import { createSlice } from '@reduxjs/toolkit'

import * as API from 'api/tenants/tenants'
import type {
  DataConnectionInfo,
  DataConnectionInfoResponse,
  TenantApplicationSettingType,
  TenantData,
  TenantEditDataType,
  TenantListResponse,
  TenantResponse,
} from 'api/tenants/types'

import { handleApiError, commonParams } from 'slices/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AppThunk, RootState } from 'store'

type TenantState = TenantListResponse & {
  isRequesting: boolean
  errorMessage: string
  tenant?: TenantData
  tenantWithDate?: TenantData
  dataConnectionInfo: DataConnectionInfo[]
}

const initialState: TenantState = {
  isRequesting: false,
  errorMessage: '',
  partialTenants: [],
  tenant: undefined,
  tenantWithDate: undefined,
  dataConnectionInfo: [],
}

const formatTenantData = (action: PayloadAction<TenantResponse>) => {
  const startTime = action.payload.tenant.businessStartTime.split(':')
  const endTime = action.payload.tenant.businessEndTime.split(':')
  return {
    ...action.payload.tenant,
    businessEndTime:
      Number(endTime[0]) < Number(startTime[0]) ||
      (Number(endTime[0]) === Number(startTime[0]) && Number(endTime[1]) <= Number(startTime[1]))
        ? `${Number(endTime[0]) + 24}:${endTime[1]}`
        : action.payload.tenant.businessEndTime,
  }
}

export const tenantsSlice = createSlice({
  name: 'tenants',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getTenantListSuccess: (state, action: PayloadAction<TenantListResponse>) => {
      state.isRequesting = false
      state.partialTenants = action.payload.partialTenants
    },
    getTenantSuccess: (state, action: PayloadAction<TenantResponse>) => {
      state.isRequesting = false
      state.tenant = formatTenantData(action)
    },
    getTenantWithDateSuccess: (state, action: PayloadAction<TenantResponse>) => {
      state.isRequesting = false
      state.tenantWithDate = formatTenantData(action)
    },
    getDataConnectionInfoSuccess: (state, action: PayloadAction<DataConnectionInfoResponse>) => {
      state.isRequesting = false
      state.dataConnectionInfo = action.payload.csvSettings
    },
    requestSuccess: state => {
      state.isRequesting = false
    },
  },
})

export const {
  startRequest,
  clearErrorMessage,
  apiFailure,
  getTenantListSuccess,
  getTenantSuccess,
  getDataConnectionInfoSuccess,
  requestSuccess,
  getTenantWithDateSuccess,
} = tenantsSlice.actions

export const getTenantList = (): AppThunk => async dispatch => {
  dispatch(startRequest())

  try {
    const res = await API.getTenantList()
    dispatch(getTenantListSuccess(res))
  } catch (res) {
    handleApiError(res, dispatch, apiFailure)
  }
}

export const getTenant =
  (tenantId: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const res = await API.getTenant(tenantId)
      dispatch(getTenantSuccess(res))
    } catch (res) {
      handleApiError(res, dispatch, apiFailure)
    }
  }

export const getTenantWithDate =
  (date?: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())

    try {
      const res = await API.getTenant(commonParams(getState).tenantId, date)
      dispatch(getTenantWithDateSuccess(res))
    } catch (res) {
      handleApiError(res, dispatch, apiFailure)
    }
  }

export const createTenant =
  (data: TenantEditDataType): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const res = await API.createTenant(data)
      dispatch(requestSuccess())
      dispatch(getTenant(res.id))
    } catch (res) {
      handleApiError(res, dispatch, apiFailure)
    }
  }

export const updateTenant =
  (tenantId: number, data: TenantEditDataType): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      await API.updateTenant(tenantId, data)
      dispatch(requestSuccess())
      dispatch(getTenant(tenantId))
    } catch (res) {
      handleApiError(res, dispatch, apiFailure)
    }
  }

export const updateTenantStatus =
  (tenantId: number, isActive: boolean): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      await API.updateTenantStatus(tenantId, isActive)
      dispatch(requestSuccess())
      dispatch(getTenant(tenantId))
    } catch (res) {
      handleApiError(res, dispatch, apiFailure)
    }
  }

export const updateApplications =
  (tenantId: number, data: TenantApplicationSettingType[]): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      await API.updateTenantApplication(tenantId, data)
      dispatch(requestSuccess())
      dispatch(getTenant(tenantId))
    } catch (res) {
      handleApiError(res, dispatch, apiFailure)
    }
  }

export const getDataConnectionInfo = (): AppThunk => async dispatch => {
  dispatch(startRequest())

  try {
    const res = await API.getDataConnectionInfo()
    dispatch(getDataConnectionInfoSuccess(res))
  } catch (res) {
    handleApiError(res, dispatch, apiFailure)
  }
}

export const selectTenantsStatus = (state: RootState) => ({ ...state.tenants })

export default tenantsSlice.reducer
