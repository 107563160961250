import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { ModalBody, ModalFooter, Button } from 'reactstrap'

import * as NetworkErrorDialogSlice from 'slices/networkErrorDialogSlice'
import { ERROR_STATUS_CODE } from 'slices/utils'

import { Modal } from 'components/common'

type NetworkErrorDialogBodyProps = {
  code: string
  errorMessage: string
}

const NetworkErrorDialogBody = ({ code, errorMessage }: NetworkErrorDialogBodyProps) => {
  return (
    <ModalBody>
      {code === ERROR_STATUS_CODE.UNREACHABLE && (
        <div className="d-flex flex-column gap-4">
          <div className="font-large fw-bold">ネットワークエラー</div>
          <div>ネットワークに接続されていないため、アクセスできませんでした。接続した状態で再度お試しください。</div>
        </div>
      )}
      {code === ERROR_STATUS_CODE.UNKNOWN && (
        <div className="d-flex flex-column gap-4">
          <div className="font-large fw-bold">予期せぬ不具合が発生しました</div>
          <div>問題が発生しています。担当者への連絡及び、画面キャプチャと発生日時の共有をお願いします。</div>
        </div>
      )}
      {![ERROR_STATUS_CODE.UNREACHABLE, ERROR_STATUS_CODE.UNKNOWN].includes(code) && (
        <div className="d-flex flex-column gap-4">
          <div className="font-large fw-bold">問題が発生しました</div>
          <div>{errorMessage || '問題が発生しているため、しばらくしてから、再度アクセスしてください。'}</div>
          {!errorMessage && <div>エラーコード：{code}</div>}
        </div>
      )}
    </ModalBody>
  )
}

export const NetworkErrorDialog = () => {
  const { show, code, errorMessage } = useSelector(NetworkErrorDialogSlice.selectNetworkErrorDialogStatus, shallowEqual)

  const dispatch = useDispatch()
  const close = () => {
    dispatch(NetworkErrorDialogSlice.close())
  }

  return (
    <Modal isOpen={show} zIndex={3000}>
      <NetworkErrorDialogBody code={code} errorMessage={errorMessage} />
      <ModalFooter className="d-flex justify-content-end">
        <Button outline onClick={close}>
          閉じる
        </Button>
      </ModalFooter>
    </Modal>
  )
}
