import { createSlice } from '@reduxjs/toolkit'

import type { GetManualInputResponse, PutManualInputType } from 'api/work_results/types'
import * as API from 'api/work_results/work_results'

import { handleApiError, checkStatus } from 'slices/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AppThunk, RootState } from 'store'

type WorkResultsState = {
  isRequesting: boolean
  errorMessage: string
  workResults?: GetManualInputResponse
}

const initialState: WorkResultsState = {
  isRequesting: false,
  errorMessage: '',
  workResults: undefined,
}

export const workResultsSlice = createSlice({
  name: 'workResults',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getWorkResultsSuccess: (state, action: PayloadAction<GetManualInputResponse>) => {
      state.isRequesting = false
      state.workResults = action.payload
    },
    updateWorkResultsSuccess: state => {
      state.isRequesting = false
    },
  },
})

export const { startRequest, clearErrorMessage, apiFailure, getWorkResultsSuccess, updateWorkResultsSuccess } =
  workResultsSlice.actions

export const getWorkResults =
  (workDate: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const res = await API.getWorkResults(workDate)
      dispatch(getWorkResultsSuccess(res))
    } catch (res) {
      handleApiError(res, dispatch, apiFailure)
    }
  }

const getStatus = async (workDate: string, revision: number) => {
  const { retryInterval, result } = await API.getAggregateStatus(workDate, revision)
  if (result) {
    return
  }
  return retryInterval
}

export const updateWorkResults =
  (workDate: string, workResults: PutManualInputType): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const { revision } = await API.postWorkResults(workDate, workResults)
      await checkStatus(() => getStatus(workDate, revision), dispatch)
      dispatch(updateWorkResultsSuccess())
    } catch (res) {
      handleApiError(res, dispatch, apiFailure)
    }
  }

export const csvImportWorkResults =
  (workDate: string, workResults: FormData): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const { revision } = await API.importWorkResults(workDate, workResults)
      await checkStatus(() => getStatus(workDate, revision), dispatch)
      dispatch(updateWorkResultsSuccess())
    } catch (res) {
      handleApiError(res, dispatch, apiFailure)
    }
  }

export const selectWorkResultsStatus = (state: RootState) => ({ ...state.workResults })

export default workResultsSlice.reducer
