import { createSlice } from '@reduxjs/toolkit'
import { omit } from 'es-toolkit'

import type {
  CreateTenantUserProps,
  CreateUserProps,
  DisplayFilterResponse,
  PartialUserData,
  UpdateDisplayFilterProps,
  UpdateTenantUserProps,
  UpdateUserPermissionProps,
  UpdateUserProps,
  UpdateUserTenantProps,
  UserData,
  UserHasTenantsType,
  UserListResponse,
  UserResponse,
} from 'api/users/types'
import * as API from 'api/users/users'

import { handleApiError, commonParams } from 'slices/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AppThunk, RootState } from 'store'

type ExtendedUser = Omit<UserData, 'userHasTenants'> & {
  userHasTenants: (Omit<UserHasTenantsType, 'isActive'> & {
    isUserActive: boolean
    tenantName: string
    salesOfficeName: string
    isTenantActive: boolean
  })[]
}
type UserState = {
  isRequesting: boolean
  errorMessage: string
  user?: ExtendedUser
  userHasTenant?: UserHasTenantsType
  allTenantUsers: PartialUserData[]
  selectedTenantUsers: PartialUserData[]
  displayFilter?: DisplayFilterResponse
}

const initialState: UserState = {
  isRequesting: false,
  errorMessage: '',
  allTenantUsers: [],
  selectedTenantUsers: [],
  user: undefined,
  userHasTenant: undefined,
  displayFilter: undefined,
}

const createExtendedUser = (data: UserResponse): ExtendedUser => {
  const { user, tenants } = data
  const extendedUserHasTenants = user.userHasTenants.map(userTenant => {
    const tenant = tenants.find(t => t.id === userTenant.id)
    return {
      ...omit(userTenant, ['isActive']),
      isUserActive: userTenant.isActive,
      tenantName: tenant?.name || '',
      salesOfficeName: tenant?.salesOfficeName || '',
      isTenantActive: tenant?.isActive || false,
    }
  })

  return {
    ...user,
    userHasTenants: extendedUserHasTenants,
  }
}

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getUserListSuccess: (state, action: PayloadAction<UserListResponse>) => {
      state.isRequesting = false
      state.allTenantUsers = action.payload.partialUsers
    },
    getTenantUserListSuccess: (state, action: PayloadAction<UserListResponse>) => {
      state.isRequesting = false
      state.selectedTenantUsers = action.payload.partialUsers
    },
    createUserSuccess: state => {
      state.isRequesting = false
    },
    getUserSuccess: (state, action: PayloadAction<UserResponse>) => {
      state.isRequesting = false
      state.user = createExtendedUser(action.payload)
    },
    updateUserSuccess: (state, action: PayloadAction<UserResponse>) => {
      state.isRequesting = false
      state.user = createExtendedUser(action.payload)
    },
    deleteUserSuccess: state => {
      state.isRequesting = false
    },
    deleteTenantUserSuccess: state => {
      state.isRequesting = false
    },
    updateUserTenantSuccess: (state, action: PayloadAction<UserResponse>) => {
      state.isRequesting = false
      state.user = createExtendedUser(action.payload)
    },
    updateUserPermissionSuccess: (state, action: PayloadAction<UserResponse>) => {
      state.isRequesting = false
      state.user = createExtendedUser(action.payload)
    },
    displayFilterSuccess: (state, action: PayloadAction<DisplayFilterResponse>) => {
      state.isRequesting = false
      state.displayFilter = action.payload
    },
  },
})

export const {
  startRequest,
  clearErrorMessage,
  apiFailure,
  getUserListSuccess,
  createUserSuccess,
  getUserSuccess,
  updateUserSuccess,
  deleteUserSuccess,
  getTenantUserListSuccess,
  updateUserTenantSuccess,
  deleteTenantUserSuccess,
  updateUserPermissionSuccess,
  displayFilterSuccess,
} = usersSlice.actions

export const getUserList = (): AppThunk => async dispatch => {
  dispatch(startRequest())

  try {
    const res = await API.getUserList()
    dispatch(getUserListSuccess(res))
  } catch (res) {
    handleApiError(res, dispatch, apiFailure)
  }
}

export const createUser =
  (data: CreateUserProps): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      await API.createUser(data)
      dispatch(createUserSuccess())
      dispatch(getUserList())
    } catch (res) {
      handleApiError(res, dispatch, apiFailure)
    }
  }

export const getUser =
  (userId: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const res = await API.getUser(userId)
      dispatch(getUserSuccess(res))
    } catch (res) {
      handleApiError(res, dispatch, apiFailure)
    }
  }

export const updateUser =
  (data: UpdateUserProps): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())

    try {
      const res = await API.updateUser(commonParams(getState).userId, data)
      dispatch(updateUserSuccess(res))
      dispatch(getUserList())
    } catch (res) {
      handleApiError(res, dispatch, apiFailure)
    }
  }

export const deleteUser =
  (userId: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())

    try {
      await API.deleteUser(userId)
      const params = commonParams(getState)
      dispatch(deleteUserSuccess())
      if (params.userId && userId !== params.userId) {
        dispatch(getUserList())
      }
    } catch (res) {
      handleApiError(res, dispatch, apiFailure)
    }
  }

export const updateUserTenant =
  (userId: string, tenantId: number, data: UpdateUserTenantProps): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const res = await API.updateUserTenant(userId, tenantId, data)
      dispatch(updateUserTenantSuccess(res))
      dispatch(getTenantUserList(tenantId))
    } catch (res) {
      handleApiError(res, dispatch, apiFailure)
    }
  }

export const getTenantUserList =
  (tenantId: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const res = await API.getTenantUserList(tenantId)
      dispatch(getTenantUserListSuccess(res))
    } catch (res) {
      handleApiError(res, dispatch, apiFailure)
    }
  }

export const createTenantUser =
  (tenantId: number, data: CreateTenantUserProps): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      await API.createTenantUser(tenantId, data)
      dispatch(createUserSuccess())
      dispatch(getTenantUserList(tenantId))
    } catch (res) {
      handleApiError(res, dispatch, apiFailure)
    }
  }

export const getTenantUser =
  (userId: string, tenantId?: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())

    try {
      const res = await API.getTenantUser(tenantId ?? commonParams(getState).tenantId, userId)
      dispatch(getUserSuccess(res))
    } catch (res) {
      handleApiError(res, dispatch, apiFailure)
    }
  }

export const updateTenantUser =
  (data: UpdateTenantUserProps): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())

    try {
      const params = commonParams(getState)
      const res = await API.updateTenantUser(data)
      dispatch(updateUserSuccess(res))
      dispatch(getTenantUserList(params.tenantId))
    } catch (res) {
      handleApiError(res, dispatch, apiFailure)
    }
  }

export const deleteTenantUser =
  (userId: string, tenantId: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())

    try {
      await API.deleteTenantUser(tenantId, userId)
      dispatch(deleteTenantUserSuccess())
      const params = commonParams(getState)
      if (params.userId && userId !== params.userId) {
        dispatch(getTenantUserList(tenantId))
      }
    } catch (res) {
      handleApiError(res, dispatch, apiFailure)
    }
  }

export const updateTenantUserPermission =
  (userId: string, data: UpdateUserPermissionProps): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const res = await API.updateTenantUserPermission(userId, data)
      dispatch(updateUserPermissionSuccess(res))
    } catch (res) {
      handleApiError(res, dispatch, apiFailure)
    }
  }

export const getDisplayFilter = (): AppThunk => async dispatch => {
  dispatch(startRequest())

  try {
    const res = await API.getDisplayFilter()
    dispatch(displayFilterSuccess(res))
  } catch (res) {
    handleApiError(res, dispatch, apiFailure)
  }
}

export const updateDisplayFilter =
  (data: UpdateDisplayFilterProps): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const res = await API.updateDisplayFilter(data)
      dispatch(displayFilterSuccess(res))
    } catch (res) {
      handleApiError(res, dispatch, apiFailure)
    }
  }

export const selectUsersStatus = (state: RootState) => ({ ...state.users })

export default usersSlice.reducer
