import { createSlice } from '@reduxjs/toolkit'
import { sortBy } from 'es-toolkit'

import * as API from 'api/schedule_types/schedule_types'
import type {
  PartialScheduleTypeData,
  RelatedScheduleTypeListResponse,
  ScheduleTypeData,
  ScheduleTypeEditDataType,
  ScheduleTypeListResponse,
  ScheduleTypeResponse,
} from 'api/schedule_types/types'

import { handleApiError } from 'slices/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AppThunk, RootState } from 'store'

export type ExtendAllScheduleTypeListData = PartialScheduleTypeData & {
  workspaceName: string
}

type ScheduleTypeState = {
  isRequesting: boolean
  errorMessage: string
  scheduleType?: ScheduleTypeData
  partialScheduleTypes: PartialScheduleTypeData[]
  allScheduleTypes: ExtendAllScheduleTypeListData[]
  scheduleTypeWorkspaceId: number | undefined
}

const initialState: ScheduleTypeState = {
  isRequesting: false,
  errorMessage: '',
  scheduleType: undefined,
  partialScheduleTypes: [],
  allScheduleTypes: [],
  scheduleTypeWorkspaceId: undefined,
}

export const scheduleTypesSlice = createSlice({
  name: 'scheduleTypes',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getScheduleTypeListSuccess: (state, action: PayloadAction<ScheduleTypeListResponse>) => {
      state.isRequesting = false
      state.partialScheduleTypes = sortBy(action.payload.partialScheduleTypes, ['name'])
      state.scheduleTypeWorkspaceId = action.payload.partialWorkspace.id
    },
    getScheduleTypeSuccess: (state, action: PayloadAction<ScheduleTypeResponse>) => {
      state.isRequesting = false
      state.scheduleType = action.payload.scheduleType
    },
    createScheduleTypeSuccess: state => {
      state.isRequesting = false
    },
    updateScheduleTypeSuccess: state => {
      state.isRequesting = false
    },
    deleteScheduleTypeSuccess: state => {
      state.isRequesting = false
    },
    getAllScheduleTypesSuccess: (state, action: PayloadAction<RelatedScheduleTypeListResponse>) => {
      state.isRequesting = false
      state.allScheduleTypes = sortBy(action.payload.partialScheduleTypes, ['name'])
        .map(scheduleType => {
          const workspaceName =
            action.payload.partialWorkspaces.find(workspace => workspace.id === scheduleType.workspaceId)?.name || ''
          return { ...scheduleType, workspaceName }
        })
        .filter(scheduleType => scheduleType.workspaceName)
    },
  },
})

export const {
  startRequest,
  clearErrorMessage,
  apiFailure,
  getScheduleTypeListSuccess,
  getScheduleTypeSuccess,
  createScheduleTypeSuccess,
  updateScheduleTypeSuccess,
  deleteScheduleTypeSuccess,
  getAllScheduleTypesSuccess,
} = scheduleTypesSlice.actions

export const getScheduleTypeList =
  (workspaceId: number, workDate?: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const res = await API.getScheduleTypeList(workspaceId, workDate)
      dispatch(getScheduleTypeListSuccess(res))
    } catch (res) {
      handleApiError(res, dispatch, apiFailure)
    }
  }

export const getScheduleType =
  (workspaceId: number, scheduleTypeId: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const res = await API.getScheduleType(workspaceId, scheduleTypeId)
      dispatch(getScheduleTypeSuccess(res))
    } catch (res) {
      handleApiError(res, dispatch, apiFailure)
    }
  }

export const createScheduleType =
  (workspaceId: number, data: ScheduleTypeEditDataType): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const res = await API.createScheduleType(workspaceId, data)
      dispatch(createScheduleTypeSuccess())
      dispatch(getScheduleTypeList(workspaceId))
      dispatch(getScheduleType(workspaceId, res.id))
    } catch (res) {
      handleApiError(res, dispatch, apiFailure)
    }
  }

export const updateScheduleType =
  (workspaceId: number, scheduleTypeId: number, data: ScheduleTypeEditDataType): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      await API.updateScheduleType(workspaceId, scheduleTypeId, data)
      dispatch(updateScheduleTypeSuccess())
      dispatch(getScheduleTypeList(workspaceId))
      dispatch(getScheduleType(workspaceId, scheduleTypeId))
    } catch (res) {
      handleApiError(res, dispatch, apiFailure)
    }
  }

export const deleteScheduleType =
  (workspaceId: number, scheduleTypeId: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      await API.deleteScheduleType(workspaceId, scheduleTypeId)
      dispatch(deleteScheduleTypeSuccess())
      dispatch(getScheduleTypeList(workspaceId))
    } catch (res) {
      handleApiError(res, dispatch, apiFailure)
    }
  }

export const getRelatedScheduleType =
  (workDate?: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const res = await API.getRelatedScheduleType(workDate)
      dispatch(getAllScheduleTypesSuccess(res))
    } catch (res) {
      handleApiError(res, dispatch, apiFailure)
    }
  }

export const selectScheduleTypesStatus = (state: RootState) => ({ ...state.scheduleTypes })

export default scheduleTypesSlice.reducer
