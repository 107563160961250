import { Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap'

import { Notification } from 'components/common/Notification/Notification'

import logo from './logo.svg'

import styles from './styles.module.scss'

import type { ReactNode } from 'react'

type Props = {
  showTitle?: boolean
  subtitle?: string
  image?: string
  title?: string
  errorMessage?: string
  onHideNotification?: () => void
  children: ReactNode
}

export const SinglePageCard = (props: Props) => {
  const { showTitle = true } = props
  return (
    <>
      <Notification
        className={styles.notification}
        errorMessage={props.errorMessage}
        error={!!props.errorMessage}
        hide={() => props.onHideNotification?.()}
      />

      <div className="d-flex vh-100 align-items-center justify-content-center">
        <div className={styles.card}>
          <Card>
            {showTitle ? (
              <CardBody className="text-center">
                <CardTitle>
                  <img src={logo} alt="Smile Board Connect" className={styles.logoImage} />
                </CardTitle>
              </CardBody>
            ) : null}
            {props.image ? <img className={styles.image} src={props.image} alt="" /> : null}
            {props.title ? <div className={styles.svgBackground}>{props.title}</div> : null}
            {props.subtitle ? (
              <CardBody className="text-center">
                <CardSubtitle tag="h2" className="fw-bold">
                  {props.subtitle}
                </CardSubtitle>
              </CardBody>
            ) : null}
            <CardBody>{props.children}</CardBody>
          </Card>
        </div>
      </div>
    </>
  )
}
